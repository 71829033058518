import React from 'react';
import styled from 'styled-components';

/* Components */
import {colors} from "../../../common/components/colors";
import {Section, Body, ContactTitle, PreviewBlack, PreviewBlackSmall} from './components';
import {FontBody18} from '../../../common/components/fonts';
import {NumberOutlined} from "@ant-design/icons";

const Logo = styled.div`
  max-width:300px;
  filter:grayscale(1);
  & img {
    filter:grayscale(1);
    max-width: 100%;
    height:auto;
  }
`;
const FirmBio = styled(FontBody18)`
color: ${props => props.color};
padding:0;
margin:0;
  vertical-align: middle;
  @media screen and (max-width: 1680px) {
    font-size: 16px;
    line-height: 1;
  }
`;

Firm.defaultProps = {
    firm_logo: "",
    firm_name: "",
    firm_bio: "",
    firm_address: "",
    firm_sec: "",
}

export default function Firm(props) {
    const bio = props.firm_bio;
    const name = props.firm_name;
    const logo = props.firm_logo;
    const address = props.firm_address;
    const firm_sec = props.firm_sec;

    return (
        <Section backgroundColor={colors.canvaBrown} style={{position:"relative"}}>
            <ContactTitle color={colors.primary100}>Firm</ContactTitle>
            <Body>
                {logo !== null && <Logo style={{tintColor: 'gray'}}><img src={logo} alt={name}/> </Logo>}
                {(bio !== null && bio !== "") && <PreviewBlack>{bio}</PreviewBlack>}
                {(address !== null && address !== "") && <PreviewBlackSmall>{address}</PreviewBlackSmall>}
                <FirmBio >Firm SEC/CRD <NumberOutlined /> {firm_sec}</FirmBio>
            </Body>
        </Section>
    )
} 

